@import "assets/sass/_variables";

.table {
    &__default {
        background-color: $c-wh;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #e0e0e0;
        > .ant-spin-nested-loading {
            font-family: $f-second;
            > .ant-spin-container {
                .ant-table {
                    &-thead {
                        > tr {
                            > .ant-table-cell {
                                border-bottom: none;
                                font-size: 15px;
                                font-weight: bold;
                                background-color: #e0e0e0;
                                padding: 15px 20px;
                                line-height: 1;
                                &.ant-table-column-has-sorters {
                                    .ant-table-column-sorters {
                                        display: flex;
                                        justify-content: space-between;
                                        padding: 0;
                                        .ant-table-column-sorter{
                                            &-inner{
                                                .anticon{
                                                    &.active{
                                                        color: $c-2;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &:hover {
                                    background-color: #d6d6d6;
                                }
                            }
                        }
                    }
                    &-tbody {
                        > tr {
                            > .ant-table-cell {
                                padding: 10px 20px;
                                font-size: 13px;
                                .ant-space {
                                    &-item {
                                        > .ant-btn {
                                            padding: 0;
                                            width: 30px;
                                            height: 30px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            background-color: $c-gr;
                                            > [class*="fa-"] {
                                                margin-top: 2.5px;
                                                color: $c-2;
                                                margin-left: 2px;
                                                font-size: 17px;
                                                margin-right: 0;
                                            }
                                        }
                                    }
                                }
                            }
                            &:hover {
                                > .ant-table-cell {
                                    background-color: rgb(248, 248, 248);
                                }
                            }
                            &:nth-child(even) {
                                > .ant-table-cell {
                                    background-color: rgb(247, 247, 247);
                                }
                            }
                        }
                        .ant-table-placeholder {
                            > .ant-table-cell {
                                > .empty {
                                    margin: 50px auto;
                                }
                            }
                        }
                    }
                    &-pagination {
                        padding-right: 20px;
                        .ant-pagination {
                            &-prev,
                            &-next {
                                .ant-pagination {
                                    &-item-link {
                                        color: $c-1;
                                        .anticon {
                                            font-size: 16px;
                                        }
                                        &::after {
                                            font-size: 13px;
                                        }
                                    }
                                }
                            }
                            &-prev {
                                .ant-pagination {
                                    &-item-link {
                                        &::after {
                                            content: "Anterior";
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                            &-next {
                                .ant-pagination {
                                    &-item-link {
                                        flex-direction: row-reverse;
                                        &::after {
                                            content: "Siguiente";
                                        }
                                    }
                                }
                            }
                            &-item {
                                border-left: none;
                                border-bottom: none;
                                border-right: none;
                                border-radius: 0;
                                &-active {
                                    border-top-color: $c-2;
                                    border-top-width: 2px;
                                }
                                > a {
                                    color: $c-1;
                                }
                                &-link {
                                    display: flex;
                                    align-items: center;
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
