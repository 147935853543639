@import "assets/sass/reset";
@import "assets/sass/load-fonts";
@import "assets/sass/variables";
@import "assets/sass/components/dropdowns";
@import "assets/sass/components/drawers";
@import "assets/sass/components/collapses";
@import "assets/sass/components/buttons";
@import "assets/sass/components/forms";
@import "assets/sass/components/table";
@import "assets/sass/components/modals";
@import "assets/sass/components/tooltips";

html,
body,
#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
a {
    &:hover {
        text-decoration: none;
    }
}

// delete outline
* {
    outline: none !important;
    box-sizing: border-box;
    &:focus {
        outline: none !important;
    }
}

// titles
.title__ppal {
    font-family: $f-ppal-r;
    word-break: break-word;
    position: relative;
    line-height: 1;
    strong {
        font-family: $f-ppal-m;
    }
    &--extra-bold {
        strong {
            font-family: $f-ppal-b;
        }
    }
    &--c-wh {
        color: $c-wh;
    }
    &--c-1 {
        color: $c-1;
    }
    &--c-dr {
        color: $c-dr;
    }

    &--sm {
        font-size: 30px;
    }
    &--md {
        font-size: 40px;
    }
    &--center {
        text-align: center;
    }

    // customize
    &--line {
        padding-bottom: 10px;
        &::after {
            content: "";
            width: 60px;
            height: 3px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        &-c-3 {
            &::after {
                background-color: $c-3;
            }
        }
        &-c-2 {
            &::after {
                background-color: $c-2;
            }
        }
    }

    // flex
    &--column {
        display: flex;
        flex-direction: column;
        > span {
            font-size: 18px;
            margin-bottom: -2px;
            color: $c-2;
        }
    }
}

// sections
.sec {
    &__default {
        padding: 70px 0;
        position: relative;

        > .list__dots{
            &.solid{
                &--r-t{
                    top: 0;
                    right: 50px;
                    transform: scale(.7);
                    transform-origin: right center;
                }
                &--l-b{
                    bottom: -50px;
                    left: 0;
                    transform: scale(.7);
                    transform-origin: left center;
                }
            }
        }

        &.c-gr {
            background-color: $c-gr;
        }

        &--n-b {
            padding-bottom: 0;
        }


        @media (min-width: 601px) {
            &-top{
                &--over{
                    > .container{
                        margin-top: -100px !important;
                    }
                }
            }
        }
        @media (max-width: 600px) {
            padding: 30px 0;
            &-top{
                &--over{
                    > .container{
                        margin-top: -50px !important;
                    }
                }
            }
        }
    }
}
