@import "assets/sass/_variables";

.dropdown {
    &__profile {
        width: 170px;
        min-width: auto !important;
        .ant-dropdown-menu {
            font-family: $f-second;
            border-radius: 5px;
            padding: 0;
            overflow: hidden;
            &-item {
                font-size: 14px;
                padding: 10px 15px;
                display: flex;
                align-items: center;
                > [class*="fa-"] {
                    margin-right: 7px;
                    font-size: 15px;
                }
                > span {
                    line-height: 1;
                }
                &:hover {
                    color: $c-1;
                }

                &.sign-out {
                    background-color: $c-1;
                    > [class*="fa-"],
                    > span{
                        color: $c-wh;
                    }
                }
            }
            &-user {
                padding: 10px 15px;
                > .info {
                    line-height: 1;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    span {
                        font-weight: 400;
                        font-size: 13px;
                        margin-bottom: 2px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.ant-select-dropdown {
    border-radius: 10px 10px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    padding: 0;

    .ant-select-item {
        padding: 0;
        &-option {
            padding: 10px 15px;
            &-content {
                font-size: 14px;
            }
            &:hover,
            &-selected {
                background-color: #e8f4f6;
                color: $c-dr;
            }
        }
    }
}
