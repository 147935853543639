@import '../variables';
@import '../mixins';

.alert {
    padding: 5px 10px 0px 25px;
    color: $c-err;
    position: relative;
    font-family: $f-ppal-r;
    > span {
        &.bold {
            font-weight: bold;
        }
    }
    &::before {
        content: '\f06a';
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 7px;
        @include fontAwesomePSEUDO(16px, center);
    }
    &-info {
        display: flex;
        align-items: center;
        grid-gap: 10px;
        font-family: $f-second;
        padding: 10px;
        border: 1px solid $c-1;
        border-radius: 15px;
        margin-bottom: 25px;
        color: $c-1;

        > em {
            font-size: 40px;
            color: $c-2;
        }
        >p {
            line-height: 1.2;
            font-size: 14px;
            color: $c-1;
            > strong {
                font-weight: 600;
            }
        }
        @media (max-width: 320px) {
            flex-direction: column;
            >p {
                text-align: center;
            }
        }
    }
}