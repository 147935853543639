@import "assets/sass/_variables";
@import "assets/sass/_mixins";

.drawer {
    &__default {
        .ant-drawer-content-wrapper {
            .ant-drawer-content {
                .ant-drawer-wrapper-body {
                    .ant-drawer {
                        &-header {
                            font-family: $f-ppal-m;
                            .ant-drawer-title {
                                font-size: 20px;
                            }
                        }
                        &-body {
                            .subtitle {
                                font-size: 16px;
                                margin-bottom: 7px;
                                font-family: $f-ppal-r;
                            }
                        }
                    }
                }
            }
        }
    }
    &__profile {
        .ant-drawer-content-wrapper {
            .ant-drawer-content {
                .ant-drawer-wrapper-body {
                    .ant-drawer {
                        &-body {
                            display: flex;
                            flex-direction: column;
                            padding: 0;
                            border-top: none;
                            .profile__user {
                                display: flex;
                                padding: 20px;
                                background-color: $c-gr;
                            }
                        }
                    }
                }
            }
        }
    }
}
