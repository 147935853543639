@import "assets/sass/_variables";
$s-control: 40px;

// select
.ant-select {
    width: 100%;
    &-single {
        &:not(.ant-select-customize-input) {
            .ant-select-selector {
                height: $s-control;
                border-radius: $s-control;
                padding: 0 24px;
                border-color: rgba(0, 0, 0, 0.25);
                .ant-select-selection {
                    &-search {
                        left: 25px;
                        &-input {
                            font-family: $f-ppal-m;
                            height: 40px;
                        }
                    }
                    &-item,
                    &-placeholder {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        font-family: $f-ppal-m;
                        line-height: 1;
                    }
                }
            }
        }
    }
    &-focused {
        &:not(.ant-select-disabled) {
            &.ant-select {
                &:not(.ant-select-customize-input) {
                    > .ant-select-selector {
                        border-color: $c-2;
                        box-shadow: 0 0 9px 0 rgba(0, 174, 199, 0.5);
                    }
                }
            }
        }
    }
    &:not(.ant-select-disabled) {
        &:hover {
            .ant-select-selector {
                border-color: transparent;
                box-shadow: 0 0 5px 0 rgba(0, 174, 199, 0.5);
            }
        }
    }
    &-dropdown {
        .ant-select-item {
            font-family: $f-second;
            font-weight: 400;
        }
    }

    &--filtered {
        $control-big: 50px;
        &.ant-select {
            &-single {
                &:not(.ant-select-customize-input) {
                    .ant-select-selector {
                        height: $control-big;
                        border-radius: $control-big;
                        .ant-select-selection {
                            &-search-input {
                                height: $control-big;
                            }
                        }
                    }
                }
            }
        }
    }
}
// datepicker
.ant-picker {
    width: 100%;
    height: 40px;
    padding: 0 20px;
    border-color: rgba(0, 0, 0, 0.25);
    border-radius: 40px;

    &-input {
        > input {
            font-family: $f-second;
            font-size: 17px;
            &::placeholder {
                color: $c-wh;
            }
        }
    }
    &:hover,
    &-focused {
        border-color: $c-2 !important;
        box-shadow: none !important;
    }

    // dropdown
    &-dropdown {
        font-family: $f-second;
    }
}
// form-item
.ant-form {
    &-item {
        position: relative;
        margin-bottom: 30px;
        // input
        &-control-input {
            height: auto;
            min-height: auto;
            &-content {
                height: 100%;
                position: relative;
                .ant {
                    &-input {
                        height: $s-control;
                        border-radius: $s-control;
                        padding: 0 24px;
                        font-size: 17px;
                        font-family: $f-second;
                        border-color: transparent;
                        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                        &:hover {
                            box-shadow: 0 0 5px 0 rgba(0, 174, 199, 0.5);
                        }
                        &:placeholder-shown {
                            + .ant-label {
                                color: $c-dr;
                                top: 10px;
                            }
                        }
                        &:focus {
                            border-color: $c-2;
                            box-shadow: 0 0 9px 0 rgba(0, 174, 199, 0.5);
                            + .ant-label {
                                color: $c-1;
                                top: -24px;
                            }
                        }
                        &:not(:placeholder-shown) {
                            + .ant-label {
                                color: $c-1;
                                top: -24px;
                            }
                        }
                    }
                    &-label {
                        font-family: $f-second;
                        font-size: 16px;
                        color: $c-2;
                        position: absolute;
                        left: 24px;
                        line-height: normal;
                        transition: all 0.2s;
                        pointer-events: none;
                        &.ant-form-item-required {
                            &::before {
                                display: none !important;
                            }
                            &::after {
                                content: "*";
                                margin-left: 5px;
                                color: $c-1;
                                display: block;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
        &-label {
            position: absolute;
            z-index: 1;
            top: 9px;
            padding-bottom: 0 !important;
            left: 0;
            font-family: $f-second;
            font-size: 16px;
            color: $c-2;
            pointer-events: none;
            transition: all 0.2s;
            line-height: normal;
            > label {
                display: flex;
                flex-direction: row-reverse;
                &::before {
                    margin-left: 5px;
                    margin-right: 0 !important;
                    color: $c-1 !important;
                }
                &::after {
                    display: none;
                }
            }
            &-top {
                > .ant-form-item-label {
                    top: -22px;
                    color: $c-1;
                }
            }
        }
        &-explain {
            font-family: $f-second;
            padding-left: 20px;
            font-size: 14px;
        }
        &-has-success,
        &:focus {
            > .ant-form-item-label {
                top: -22px;
                font-size: 14px;
                > label {
                    color: $c-1;
                }
            }
            > .ant-form-item-row {
                > .ant-form-item-label {
                    top: -22px;
                    font-size: 14px;
                    > label {
                        color: $c-1;
                    }
                }
            }
        }
    }

    &--filtered {
        width: 320px;
        margin: -40px auto 40px;

        @media (max-width: 600px) {
            width: 90%;
            margin: 0 auto;
        }
    }
    &-vertical {
        .ant {
            &-form {
                &-item {
                    &-label {
                        padding: 0 24px;
                    }
                }
            }
        }
    }
}

// actions
.form-actions {
    display: flex;
    justify-content: center;
    > .ant-btn {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
}
