@import 'assets/sass/_variables';
@import 'assets/sass/_mixins';

.collapse{
    &__faqs{
        border: none;
        background-color: transparent;
        border-radius: 0;
        color: $c-dr;
        .ant-collapse-item{
            margin-bottom: 20px;
            border-bottom: none;
            background-color: $c-wh;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
            border-radius: 10px !important;
            overflow: hidden;
            .ant-collapse{
                &-header{
                    padding: 0 50px 0 25px;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    font-family: $f-ppal-m;
                    font-size: 19px;
                    color: $c-1;
                    font-weight: 500;
                    line-height: 1;
                    > .anticon{
                        display: none;
                    }
                    > .ant-collapse-expand-icon {
                        display: none;
                    }
                    &::before{
                        @include fontAwesome('\f111');
                        border-radius: 100%;
                        color: $c-2;
                        margin-right: 10px;
                        font-weight: 600;
                        font-size: 12px;
                    }
                    &::after{
                        @include fontAwesome('\f067');
                        position: absolute;
                        right: 20px;
                        color: $c-2;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                &-content{
                    &-box{
                        padding: 20px 25px;
                        > p{
                            font-size: 15px;
                            font-family: $f-second;
                        }
                        a{
                            color: $c-1;
                            text-decoration: underline;
                        }
                        strong{
                            font-weight: bold;
                        }
                    }
                }
            }
            &-active{
                .ant-collapse{
                    &-header{
                        &::after{
                            @include fontAwesome('\f068');
                        }
                    }
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}