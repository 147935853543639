@import 'assets/sass/_variables';

.modal{
    &__default{
        .ant-modal{
            &-content{
                border-radius: 15px;
                overflow: hidden;
                > .ant-modal{
                    &-header{
                        border-bottom: none;
                        padding: 10px 50px;
                    }
                    &-body{
                        padding: 30px 50px;
                    }
                }
            }
        }

        &-empty{
            .ant-modal{
                &-content{
                    > .ant-modal{
                        &-body{
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            > .empty{
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
        &-flex{
            .ant-modal{
                &-content{
                    .ant-modal{
                        &-body{
                            padding: 0;
                            display: flex;
                            .ant-form{
                                width: 100%;
                                padding: 20px 30px;
                            }
                            .panel__aditional{
                                width: 60%;
                                height: auto;
                                overflow: auto;
                                flex: 1 0 auto;
                                background-color: $c-gr;
                                padding: 20px 30px;
                                border-radius: 20px 0 0 0;
                                display: flex;
                                flex-direction: column;
                                &-title{
                                    font-family: $f-ppal-m;
                                    margin-bottom: 10px;
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
            @media (max-width: 700px){
                .ant-modal{
                    &-content{
                        .ant-modal{
                            &-body{
                                padding: 0;
                                display: flex;
                                flex-direction: column;
                                .panel__aditional{
                                    width: 100%;
                                    border-radius: 0;
                                    height: auto;
                                    overflow: visible;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}