@import '../variables';

.ant-btn{
    width: fit-content;
    height: 50px;
    border-radius: 25px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    line-height: normal !important;
    background-color: transparent;
    border: none;
    font-family: $f-ppal-m;
    font-size: 20px;
    color: $c-dr;

    &.solid{
        &.c-1{
            background-color: $c-1;
            color: $c-wh;
        }
        &.c-2{
            background-color: $c-2;
            color: $c-wh;
        }
        &.c-3{
            background-color: $c-3;
            color: $c-1;
        }
        &:hover{
            box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, .15);
        }
    }
    &.outline{
        border: 1px solid transparent;
        &.c-1{
            border-color: $c-1;
            color: $c-1;
        }
        &.c-2{
            border-color: $c-2;
            color: $c-2;
            &:hover{
                background-color: #E8F4F6;
            }
            &:focus{
                background-color: #B2E4EB;
            }
        }
    }

    > [class*="fa-"]{
        margin-right: 10px;
    }

    &--tx{
        &-uppercase{
            text-transform: uppercase;
        }
    }

    &-loading{
        > [class*="fa-"]{
            display: none;
        }
    }

    &:hover,
    &:active,
    &:focus{
        color: $c-dr;
    }

    &:disabled{
        opacity: .5;
    }
}