// colors
$c-dr: #575756;
$c-gr: #eee;
$c-gr-m: rgb(223, 223, 223);
$c-gr-d: #8188aa;
$c-wh: #fff;
$c-1: #0033A0;
$c-2: #00aec7;
$c-3: #e3e829;
$c-err: #f44336;
$c-bg-gr: #f9f9fb;
$c-bg-gr-d: #eeeeee;
$c-bg-tb-h: #E0E0E0;
$c-bd-c: #EBEBEB;
$c-black: #000000;

//border-radius
$br-i: 40px;
$br-c: 20px;

// fonts
$f-ppal-r: 'Sura-Regular';
$f-ppal-m: 'Sura-SemiBold';
$f-ppal-b: 'Sura-Bold';
$f-second: 'Sura-Regular';
