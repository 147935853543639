@import "assets/sass/_variables";

.custom_section {
  padding: 70px 0 50px 0;
}

.container {
  > .title_general_deatils::after {
    width: 40%;
    min-width: 60px;
  }
}

.userdetails_container {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  font-family: $f-ppal-m;
}
.userdetails_card {
  border-radius: 10px;
  width: 60%;
  > .ant-card-body {
    position: relative;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 56px 24px 32px 24px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 5%;
    left: 50%;
    width: 1px;
    height: 90%;
    background-color: $c-bg-gr-d;
    border-radius: 10px 10px 0 0;
  }
}
.userdetails_title {
  position: absolute;
  top: 18px;
  left: 55px;
  font-size: 24px;
  color: $c-1;
}
.userdetails_card_text_sections {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  > p {
    flex: 1;
  }
}
.userdetails_text_label {
  text-align: right;
  font-size: 20px;
}
.userdetails_text_info {
  color: $c-1;
  font-size: 20px;
}
.links_sections_container {
  gap: 20px;
  justify-content: center;
}
.link_section_title_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;

  > [class*="fa-"] {
    font-size: 28px;
    color: $c-1;
  }
}
.link_section_container_root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: $c-wh;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid transparent;

  > .ant-card-body {
    padding: 20px 20px 8px 20px;
    box-sizing: border-box;
  }
}
.link_section_title {
  font-family: $f-ppal-b;
  font-size: 20px;
}
.link_section_action {
  font-family: $f-ppal-r;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  color: $c-2;
}
.list__documents {
  .ant-list-items {
    display: grid;
    .doc {
      background-color: $c-wh;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border: 1px solid transparent;
      position: relative;
      transition: all 0.2s;

      &__type {
        font-size: 20px;
        color: $c-1;
        opacity: 0.5;
        margin-bottom: 15px;
        transition: all 0.2s;
      }

      &__icon {
        position: absolute;
        top: -1px;
        right: -1px;
        padding: 10px 15px;
        background-color: $c-gr;
        color: $c-2;
        border-radius: 0 10px 0 10px;
        transition: all 0.2s;
      }
      &__name {
        font-family: $f-ppal-m;
        color: $c-dr;
        font-size: 18px;
        line-height: 1.2;
        margin-bottom: 5px;
      }
      &__date {
        font-size: 13px;
        font-family: $f-second;
        color: $c-dr;
        opacity: 0.9;
      }

      &:hover {
        border-color: $c-2;

        .doc {
          &__type {
            opacity: 1;
          }
          &__icon {
            background-color: $c-2;
            color: $c-wh;
          }
        }
      }
    }
  }

  @media (min-width: 1301px) {
    .ant-list-items {
      grid-template-columns: repeat(5, 1fr);
      gap: 20px;
    }
  }
  @media (min-width: 1101px) and (max-width: 1300px) {
    .ant-list-items {
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }
  }
  @media (min-width: 801px) and (max-width: 1100px) {
    .ant-list-items {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }
  @media (min-width: 501px) and (max-width: 800px) {
    .ant-list-items {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
  @media (max-width: 500px) {
    .ant-list-items {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    }
  }
}
