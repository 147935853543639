@font-face {
    font-family: 'Sura-Regular';
    src: local('Sura'), url(../fonts/sura/SuraSans-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Sura-SemiBold';
    src: local('Sura'), url(../fonts/sura/SuraSans-Seminegrita.otf) format('opentype');
}

@font-face {
    font-family: 'Sura-Bold';
    src: local('Sura'), url(../fonts/sura/SuraSans-Negrita.otf) format('opentype');
}
